import request from "@/request/index";  
const part_url = "/oa/specialexpenditure/";

export default{
    addOrUpdate(params) {
        let url = part_url + "addOrUpdate";
        return request.post(url,{}, params);
    },
    getList(params) {
        let url = part_url + "getList";
        return request.get(url,params, {});
    },
   
    changeStatus(id,status){
        let url = part_url + "changeStatus";
        return request.get(url,{id,status}, {});
    },
    getData(id) {
        let url = part_url + "getData";
        return request.get(url, {id}, {});
    },
    deleteItem(id){
        let url = part_url + "deleteItem";
        return request.get(url, {id}, {});
    },
    exportData(params,onProgress,onError){
        return request.downloadFile(part_url+'exportData',params,'专项资金支出申请.xls',onProgress,onError);
    },
    exportSummary(params,onProgress,onError){
        return request.downloadFile(part_url+'exportSummary',params,'专项资金支出汇总表.xls',onProgress,onError);
    },
    getSpecialFundUsed(params){
        let url = part_url + "getSpecialFundUsed";
        return request.get(url,params, {});
    },
}
