<template>
  <a-spin tip="加载中" :spinning="loading">
    <div class="tableList">
      <div :style="{ padding: '10px', margin: '0px auto' }">
        <a-form-model ref="specialexpenditureexpendform" :model="form" :rules="rules" :label-col="labelCol"
          :wrapper-col="wrapperCol" :class="{ disabled: form.status == 2 }">
          <template v-for="(item, index) in form.items">
            <a-row :key="`${index}_1`">
              <a-col :span="24" STYLE="font-size: 32px;text-align: center;text-decoration: underline;">
                票 据 整 理 单
              </a-col>
            </a-row>
            <a-row :key="`${index}_2`">
              <a-col :span="13">
                <a-form-model-item label="单位" prop="org" style="margin-bottom: 1px !important; margin-bottom: 0px">
                  <a-input v-model="item.orgName" readOnly />
                </a-form-model-item>
              </a-col>
              <a-col :span="5">
                <a-form-model-item label="日期" prop="time" STYLE="margin-bottom:1px !important;">
                  <a-date-picker v-model="item.time" valueFormat="YYYY-MM-DD" />
                </a-form-model-item>
              </a-col>
              <a-col :span="5">
                <a-form-model-item label="编号" prop="number" style="margin-bottom: 1px !important; margin-bottom: 0px">
                  <a-input v-model="item.number" />
                </a-form-model-item>
              </a-col>
            </a-row>
            <table style="width: 100%" class="formtable" :key="`${index}_3`">
              <tr>
                <td style="text-align: left" colspan="5" rowspan="3">
                  <a-form-model-item label="内容" style="margin-bottom: 0px">
                    <a-input v-model="item.content" />
                  </a-form-model-item>
                  <a-form-model-item label="金额(大写)" prop="amount" style="margin-bottom: 0px">
                    <a-input-number v-model="item.price" :precision="2"
                      style="width: 20%; float: right; margin-top: 3px" />
                    <a-input v-model="item.amount" style="width: 80%" />
                  </a-form-model-item>
                </td>
                <td style="width: 150px">付款方式</td>
                <td>出纳签字</td>
                <td rowspan="6">
                  附<br />
                  单<br />
                  据<br />
                  <a-input style="width: 60px; padding: 0px; text-align: center" v-model="item.fileCount"
                    size="small" /><br />
                  张<br />
                </td>
              </tr>
              <tr>
                <td>银行付款</td>
                <td></td>
              </tr>
              <tr>
                <td>现金付款</td>
                <td></td>
              </tr>
              <tr>
                <td rowspan="4" style="width: 100px">单位审批</td>
                <td rowspan="1" style="text-align: left">
                  <auditPrint :infoData="form" taskPrintKey="BuShiFuZeRen" taskName="项目管理部门负责人：" @callback="auditCb" />
                </td>
                <td rowspan="1" style="text-align: left">
                  <auditPrint :infoData="form" taskPrintKey="CaiWu" taskName=" 财务：" @callback="auditCb" />
                </td>
                <td rowspan="4" style="width: 110px">核算中心</td>
                <td rowspan="4" style="text-align: left">
                  票据审核：<br />
                  会计主管：<br />
                  核算中心领导：<br />
                </td>
                <td colspan="2" rowspan="3">
                  <a-row>
                    <a-col :span="24" style="padding-bottom: 15px; font-size: 20px">结算借款</a-col>
                  </a-row>
                  <a-row>
                    <a-col :span="24" style="text-align: left">
                      <a-form-model-item label="原借款" style="margin-bottom: 0px">
                      </a-form-model-item>
                    </a-col>
                  </a-row>
                  <a-row>
                    <a-col :span="24" style="text-align: left">
                      <a-form-model-item label="结算后余额" style="margin-bottom: 0px">
                      </a-form-model-item>
                    </a-col>
                  </a-row>
                </td>
              </tr>
              <tr>
                <td style="text-align: left">
                  <auditPrint :infoData="form" taskName="办公室财务副主任：" taskPrintKey="BanGongShiFuZhuRen"
                    @callback="auditCb" />
                </td>
                <td style="text-align: left">
                  <auditPrint :infoData="form" taskPrintKey="BanGongShiZhuRen" taskName=" 办公室主任：" @callback="auditCb" />
                </td>
              </tr>
              <tr>
                <td style="text-align: left">
                  <auditPrint :infoData="form" taskPrintKey="FenGuanLingDao" taskName=" 项目管理部门分管院领导："
                    @callback="auditCb" />
                </td>
                <td style="text-align: left">
                  <auditPrint :infoData="form" taskPrintKey="BanGongShiFenGuanLingDao" taskName=" 分管财务院领导："
                    @callback="auditCb" />
                </td>
              </tr>
              <tr>
                <td style="text-align: left" colspan="2">
                  <auditPrint :infoData="form" taskPrintKey="YuanChang" taskName=" 院长：" @callback="auditCb" />
                </td>
                <td colspan="2" style="text-align: left">
                  <a-form-model-item label="业务经办人" style="margin-bottom: 0px"
                    v-if="form.status == 0 || form.status == 3">
                    <a-input readOnly v-model="form.handlerName" style="width: 100%" />
                  </a-form-model-item>
                  <div style="text-align: right" v-else>
                    <img style="width: 120px" :src="form.handlerPic" v-if="form.handlerPic" />
                    <span v-else>{{ form.handlerName }} </span>
                    <div>
                      {{
                        $moment(form.reportTime, "YYYY-MM-DD").format(
                          "YYYY年MM月DD日"
                        )
                      }}
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>附件</td>
                <td colspan="7" style="text-align: left">
                  <a-form-model-item label="" v-if="index == 0" style="margin-bottom: 1px !important">
                    <a-upload :action="uploadFileUrl" :fileList="fileList0" @change="handleFileChange"
                      style="pointer-events: all;" @preview="showWpsRead" :remove="() => {
                        uploadNumber = 0;
                        return upLoadRemove;
                      }
                        ">
                      <div v-if="form.status == 0 || form.status == 3">
                        <a-button @click="uploadC(index)"><a-icon type="upload" />上传文件</a-button>
                      </div>
                    </a-upload>
                  </a-form-model-item>
                  <a-form-model-item label="" v-if="index == 1" style="margin-bottom: 1px !important">
                    <a-upload :action="uploadFileUrl" :fileList="fileList1" @change="handleFileChange"
                      style="pointer-events: all;" @preview="showWpsRead" :remove="() => {
                        uploadNumber = 1;
                        return upLoadRemove;
                      }
                        ">
                      <div v-if="form.status == 0 || form.status == 3">
                        <a-button @click="uploadC(index)"><a-icon type="upload" />上传文件</a-button>
                      </div>
                    </a-upload>
                  </a-form-model-item>
                  <a-form-model-item label="" v-if="index == 2" style="margin-bottom: 1px !important">
                    <a-upload :action="uploadFileUrl" :fileList="fileList2" @change="handleFileChange"
                      style="pointer-events: all;" @preview="showWpsRead" :remove="() => {
                        uploadNumber = 2;
                        return upLoadRemove;
                      }
                        ">
                      <div v-if="form.status == 0 || form.status == 3">
                        <a-button @click="uploadC(index)"><a-icon type="upload" />上传文件</a-button>
                      </div>
                    </a-upload>
                  </a-form-model-item>
                  <a-form-model-item label="" v-if="index == 3" style="margin-bottom: 1px !important">
                    <a-upload :action="uploadFileUrl" :fileList="fileList3" @change="handleFileChange"
                      style="pointer-events: all;" @preview="showWpsRead" :remove="() => {
                        uploadNumber = 3;
                        return upLoadRemove;
                      }
                        ">
                      <div v-if="form.status == 0 || form.status == 3">
                        <a-button @click="uploadC(index)"><a-icon type="upload" />上传文件</a-button>
                      </div>
                    </a-upload>
                  </a-form-model-item>
                  <a-form-model-item label="" v-if="index == 4" style="margin-bottom: 1px !important">
                    <a-upload :action="uploadFileUrl" :fileList="fileList4" @change="handleFileChange"
                      style="pointer-events: all;" @preview="showWpsRead" :remove="() => {
                        uploadNumber = 4;
                        return upLoadRemove;
                      }
                        ">
                      <div v-if="form.status == 0 || form.status == 3">
                        <a-button @click="uploadC(index)"><a-icon type="upload" />上传文件</a-button>
                      </div>
                    </a-upload>
                  </a-form-model-item>
                  <a-form-model-item label="" v-if="index == 5" style="margin-bottom: 1px !important">
                    <a-upload :action="uploadFileUrl" :fileList="fileList5" @change="handleFileChange"
                      style="pointer-events: all;" @preview="showWpsRead" :remove="() => {
                        uploadNumber = 5;
                        return upLoadRemove;
                      }
                        ">
                      <div v-if="form.status == 0 || form.status == 3">
                        <a-button @click="uploadC(index)"><a-icon type="upload" />上传文件</a-button>
                      </div>
                    </a-upload>
                  </a-form-model-item>
                  <a-form-model-item label="" v-if="index == 6" style="margin-bottom: 1px !important">
                    <a-upload :action="uploadFileUrl" :fileList="fileList6" @change="handleFileChange"
                      style="pointer-events: all;" @preview="showWpsRead" :remove="() => {
                        uploadNumber = 6;
                        return upLoadRemove;
                      }
                        ">
                      <div v-if="form.status == 0 || form.status == 3">
                        <a-button @click="uploadC(index)"><a-icon type="upload" />上传文件</a-button>
                      </div>
                    </a-upload>
                  </a-form-model-item>
                  <a-form-model-item label="" v-if="index == 7" style="margin-bottom: 1px !important">
                    <a-upload :action="uploadFileUrl" :fileList="fileList7" @change="handleFileChange"
                      style="pointer-events: all;" @preview="showWpsRead" :remove="() => {
                        uploadNumber = 7;
                        return upLoadRemove;
                      }
                        ">
                      <div v-if="form.status == 0 || form.status == 3">
                        <a-button @click="uploadC(index)"><a-icon type="upload" />上传文件</a-button>
                      </div>
                    </a-upload>
                  </a-form-model-item>
                </td>
              </tr>
            </table>
            <hr :key="`${index}_4`" style="margin-top: 10px" />
          </template>

        </a-form-model>
        <a-row style="text-align: center; margin-top: 20px">
          <a-col>
            <a-button type="link" v-if="form.status != 0">
              <processStatus :processModel="form" />
            </a-button>

            <a-button type="primary" v-if="form.processExecuteStatus" style="margin-right: 10px"
              @click="showaudit()">审批</a-button>
            <a-button type="primary" v-if="form.status == 0 || form.status == 3" style="margin-right: 10px"
              @click="handleSubmit(0)">保存</a-button>
            <a-popconfirm v-if="form.status == 0 || form.status == 3" title="提交后数据不可更改，是否确认？"
              @confirm="handleSubmit(1)">
              <a-button type="danger" style="margin-right: 10px">提交</a-button>
            </a-popconfirm>
            <a-button v-if="form.status == 2" @click="showWps" style="margin-right: 10px">打印</a-button>
            <a-button @click="cancelClick">取消</a-button>
          </a-col>
        </a-row>
        <a-modal title="选择用户" v-model="selectuseModal.visible" width="70%" :footer="false" destroyOnClose
          :maskClosable="false">
          <selectuser @callback="selectuserCallback" />
        </a-modal>
        <a-modal title="审批" v-model="auditModal.visible" width="40%" :footer="false" destroyOnClose
          :maskClosable="false">
          <audit :info="auditModal.info" :formValidPass="formValidPass" @callback="auditCallBack" />
        </a-modal>
        <a-modal :visible="showImgModal" :footer="false" width="80%" destroyOnClose :maskClosable="false"
          @cancel="showImghandleCancel">
          <iframe :src="imgurl" frameborder="0" width="100%" height="800px"></iframe>
        </a-modal>
      </div>
    </div>
  </a-spin>
</template>
<script>
  import selectuser from "../components/searchuser.vue";
  import specialexpenditureExpendApi from "@/api/work/specialexpenditureExpend";
  import request from "@/request";
  import pinyin from "js-pinyin";

  export default {
    name: "specialexpenditureExpend",
    data() {
      return {
        imgurl: "",
        showImgModal: false,
        uploadFileUrl: "",
        fileList0: [],
        fileList1: [],
        fileList2: [],
        fileList3: [],
        fileList4: [],
        fileList5: [],
        fileList6: [],
        fileList7: [],
        upLoadRemove: false,
        form: {
          items: [],
        },
        labelCol: { span: 6 },
        wrapperCol: { span: 17 },
        orgs: [],
        loading: false,
        selectuseModal: {
          visible: false,
          record: null,
        },
        auditModal: {
          info: null,
          visible: false,
        },
        uploadNumber: 0,
        rules: {
          // handler: [
          //   {
          //     required: true,
          //     message: '请选择编报人',
          //     trigger: 'blur',
          //     validator: (rule, value, callback) => {
          //       if (this.form.handler == null || this.form.handler == '') {
          //         callback(new Error(''));
          //         return;
          //       }
          //       callback();
          //     }
          //   }
          // ],
          // org: [
          //   {
          //     required: true,
          //     message: '请选择编报单位',
          //     trigger: 'blur',
          //     validator: (rule, value, callback) => {
          //       if (this.form.orgId.length == 0) {
          //         callback(new Error(''));
          //         return;
          //       }
          //       callback();
          //     }
          //   }
          // ],
          // time: [
          //   {
          //     required: true,
          //     message: '请选择时间',
          //     trigger: 'change'
          //   }
          // ]
        },
        formValidPass: true,
        currUser: localStorage.getItem(this.$commons.User.userName),
      };
    },
    props: {
      info: {
        type: Object,
      },
    },
    components: { selectuser },
    mounted() {
      this.getData();
      this.uploadFileUrl = request.getUploadUrl("document");

      // this.getOrgTree();
    },
    methods: {
      getfileList(v) {
        return "fileList" + v;
      },
      getPostData() {
        let formData = { ...this.form };
        // formData.orgId=formData.orgId[formData.orgId.length-1];
        if (this.info) {
          formData.id = Number(this.info.id);
        }
        delete formData.createTime;
        delete formData.creator;
        delete formData.creatorName;
        delete formData.status;
        // if(formData.time){
        //   formData.time=formData.time.format('YYYY-MM-DD')
        // }
        delete formData.auditRecords;
        delete formData.currentNode;
        delete formData.currentNodeName;
        delete formData.handler;
        delete formData.handlerName;
        delete formData.processExecuteStatus;
        delete formData.processId;
        delete formData.processStatus;
        delete formData.processModelKey;
        delete formData.reportTime;
        delete formData.taskId;
        delete formData.taskKey;
        delete formData.taskRoles;
        delete formData.taskUsers;
        delete formData.xiangmu;

        if (formData.items) {
          formData.items.forEach((item) => {
            if (item.time) {
              item.id = Number(item.id);
              item.specialExpenditureItemId = Number(
                item.specialExpenditureItemId
              );
              delete item.creator;
              delete item.creatorName;
              delete item.orgId;
              delete item.orgName;
              delete item.handler;
              delete item.handlerName;
              // item.time=item.time.format('YYYY-MM-DD')
            }
          });
        }
        return formData;
      },
      showImghandleCancel() {
        this.showImgModal = false;
      },
      showWpsRead(file) {
        let type = file.name.substring(file.name.lastIndexOf(".") + 1);
        if (
          type.toLowerCase().indexOf("doc") <= -1 &&
          type.toLowerCase().indexOf("xls") <= -1 &&
          type.toLowerCase().indexOf("ppt") <= -1
        ) {
          request
            .isImageUrlValid(file.response.url)
            .then(() => {
              this.imgurl = file.response.url;
              this.showImgModal = true;
            })
            .catch(() => {
              window.open(file.response.url, "_blank");
            });
        } else {
          let fileId =
            "file" +
            file.response.url
              .substring(
                file.response.url.lastIndexOf("/") + 1,
                file.response.url.lastIndexOf(".")
              )
              .replaceAll("-", "");
          let url = file.response.url.substring(
            file.response.url.indexOf("oabucket")
          );
          let type = file.response.url.substring(
            file.response.url.lastIndexOf(".") + 1
          );
          localStorage.removeItem("fileRrl");
          localStorage.setItem("fileRrl", url);
          localStorage.removeItem("fileName");
          localStorage.setItem("fileName", file.name);
          localStorage.removeItem("fileId");
          localStorage.setItem("fileId", fileId);
          localStorage.removeItem("fileTpye");
          localStorage.setItem("fileTpye", type);
          const jump = this.$router.resolve({
            name: "wpsRead",
            query: {
              //要传的参数
              fileName: file.name,
            },
          });

          window.open(jump.href, "_blank");
        }
      },
      uploadC(v) {
        this.uploadNumber = v;
      },
      async showaudit() {
        let validRes = await this.$refs.specialexpenditureexpendform.validate();
        if (!validRes) {
          this.$message.error("请填写必填项");
          return;
        }
        this.formValidPass = validRes;
        this.auditModal.info = this.form;
        this.auditModal.visible = true;
      },
      async auditCallBack(data) {
        let close = true;
        this.auditModal.visible = false;
        if (data != null && data.updateModel) {
          close = false;
          let validRes = await this.$refs.specialexpenditureexpendform.validate();
          if (!validRes) {
            return false;
          }
          this.loading = true;
          this.loadingTip = "保存中...";
          let formData = {
            id: this.form.id,
            specialExpenditureId: this.form.specialExpenditureId,
            reportTime: this.form.reportTime,
            xiangmu: this.form.xiangmu,
            type: this.form.type,
            handlerName: this.form.handlerName,
            handler: this.form.handler,
            items: this.form.items,
            updateOnly: true,
          };
          let res = await specialexpenditureExpendApi.addOrUpdate(formData);
          if (res.errorCode == "0000") {
            this.loading = false;
            close = true;
          }
        }
        if (close) {
          this.$emit("callback", data != null);
        }
      },
      orgChanged(v, selectedOptions) {
        if (selectedOptions == null || selectedOptions.length == 0) {
          this.form.orgName = null;
          this.form.orgId = [];
        } else {
          this.form.orgName = selectedOptions[selectedOptions.length - 1].name;
          this.form.orgId = v;
        }
        this.$forceUpdate();
      },
      onSearch(type) {
        this.selectuseModal.visible = true;
        this.selectuseModal.record = type;
      },
      getData() {
        if (this.info) {
          let _this = this;
          this.form.items = [];
          specialexpenditureExpendApi.getData(this.info.id).then((res) => {
            if (res.errorCode == this.$commons.RespCode.fail) {
              if (this.info.items.length > 0) {
                let items = [];
                this.info.items.forEach((a) => {
                  if (a.itemAmount != null && a.kemu) {
                    let o = {
                      itemAmount: a.itemAmount,
                      kemu: a.kemu,
                      content: a.kemu,
                      orgId: _this.info.orgId,
                      amount: this.$util.menoyToUppercase(a.itemAmount),
                      price: a.itemAmount,
                      orgName: "河北省教育科学研究院",
                      handlerName: _this.info.creatorName,
                      handler: _this.info.creator,
                      time: _this.info.reportTime.format("YYYY-MM-DD"),
                      specialExpenditureItemId: _this.info.id,
                      fileList: [],
                      fileCount: "",
                    };
                    items.push(o);
                  }
                });
                this.form.items = items;
                this.form.status = 0;
                this.form.id = _this.info.id;
                this.form.specialExpenditureId = _this.info.id;
                this.form.handler = _this.info.creator;
                this.form.handlerName = _this.info.creatorName;
                this.form.type = _this.info.type;
                this.form.xiangmu = _this.info.xiangmu;
                this.upLoadRemove = true;
              }
            } else if ((res.errorCode = this.$commons.RespCode.success)) {
              this.form = res.data;
              for (let ii = 0; ii < this.form.items.length; ii++) {
                let item = this.form.items[ii];
                if (item.files) {
                  var d = JSON.parse(item.files);
                  var files = [];
                  for (var i = 1; i <= d.length; ++i) {
                    var a = d[i - 1];
                    files.push({
                      uid: i,
                      name: a.title,
                      status: "done",
                      response: a,
                      url: a.url,
                    });
                  }
                  item.fileList = files;
                  this["fileList" + ii] = files;
                } else {
                  item.fileList = [];
                  this["fileList" + ii] = [];
                }
              }
              if (res.data.status == 0 || res.data.status == 3)
                this.upLoadRemove = true;
            }
          });
        } else {
          this.form.time = this.$moment();
        }
      },
      handleFileChange(fileInfo) {
        this["fileList" + this.uploadNumber] = fileInfo.fileList;
        this.form.items[this.uploadNumber].fileList = fileInfo.fileList;
        if (fileInfo.file.status == "error") {
          this.$message.error("上传失败");
        }
      },
      selectuserCallback(user) {
        this.form[this.selectuseModal.record] = user.userName;
        this.form[this.selectuseModal.record + "Name"] = user.realName;
        this.selectuseModal.visible = false;
      },
      handleSubmit(v) {
        this.form.status = v;
        if (this.form.items && this.form.items.length > 0) {
          this.form.reportTime = this.form.items[0].time;
        }
        this.form.items.forEach((e) => {
          if (e.fileList.length > 0) {
            var file = [];
            e.fileList.forEach((f) => {
              file.push({
                title: f.response.title,
                url: f.response.url,
              });
            });
            e.files = JSON.stringify(file);
          }
        });
        let formData = { ...this.form };
        delete formData.auditRecords;
        specialexpenditureExpendApi.addOrUpdate(formData).then((res) => {
          this.getData();
        });
      },
      update() {
        let data = this.getPostData();

        data.items.forEach((e) => {
          if (e.fileList.length > 0) {
            var file = [];
            e.fileList.forEach((f) => {
              file.push({
                title: f.response.title,
                url: f.response.url,
              });
            });
            e.files = JSON.stringify(file);
          }
          delete e.fileList;
        });
        specialexpenditureExpendApi.update(data).then((res) => {
          this.getData();
        });
      },
      cancelClick() {
        this.$emit("callback");
      },
      auditCb(data) {
        this.$emit("callback", data != null);
        this.getData();
      },

      showWps() {
        let templateId = "specialexpenditureexpend";
        let innerData = {};
        let items = [];
        for (let i = 0; i < this.form.items.length; i++) {
          items[i] = Object.assign({}, this.form.items[i]);
          items[i].time = this.$moment(items[i].time, "YYYY-MM-DD").format(
            "YYYY年MM月DD日"
          );
          if (items[i].handlerPic != null && items[i].handlerPic != "") {
            items[i].handlerPic =
              "http://localhost:9000/" +
              items[i].handlerPic.substring(items[i].handlerPic.indexOf("uas"));
          }
          items[i].price = items[i].price.toFixed(2);
          this.form.auditRecords.forEach((a) => {
            let key =
              "taskKey" + pinyin.getFullChars(a.taskKey.replace(/\d+/g, ""));
            if (a.taskPrintKey != null) {
              key = "taskKey" + a.taskPrintKey;
            }
            if (items[i][key] == null) {
              items[i][key] = [];
            }
            let auditPic = "";
            if (a.auditUserSignPicture != null && a.auditUserSignPicture != "") {
              let s = a.auditUserSignPicture.indexOf("uas");
              auditPic =
                "http://localhost:9000/" +
                a.auditUserSignPicture.substring(
                  a.auditUserSignPicture.indexOf("uas")
                );
            }
            let task = {
              auditRemark: a.auditRemark,
              auditPic: auditPic,
              auditName: a.auditUserName,
              auditTime: this.$moment(a.time, "YYYY-MM-DD").format(
                "YYYY年MM月DD日"
              ),
            };
            items[i][key].push(task);
          });
        }
        innerData.items = items;
        localStorage.setItem("innerData", JSON.stringify(innerData));
        localStorage.removeItem("publicFileId");
        const jump = this.$router.resolve({
          name: "wpsedit",
          query: {
            //要传的参数
            templateId: templateId,
            fileType: "public",
            openType: "create",
            fileName: "票据整理单",
            tempUrl: "writeNumberTemp",
          },
        });
        window.open(jump.href, "_blank");
      },
    },
  };
</script>
